import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'
import CONTRACTS from './contracts'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.myield,
  //   earningToken: serializedTokens.myield,
  //   contractAddress: CONTRACTS.masterChef,
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 1,
  //   stakingToken: serializedTokens.milk,
  //   earningToken: serializedTokens.myield,
  //   contractAddress: CONTRACTS.masterChef,
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '1', // TODO: change (lower APR)
  //   sortOrder: 1,
  //   isFinished: false,
  // },
  // {
  //   sousId: 1,
  //   stakingToken: serializedTokens.milk,
  //   earningToken: serializedTokens.test,
  //   contractAddress: {
  //     10001: '0x5e73a7a0fb36fCfE3dCc7dFB01ee9B32819b69ba',
  //     10000: '0x5e73a7a0fb36fCfE3dCc7dFB01ee9B32819b69ba',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '1.0',
  //   sortOrder: 2,
  //   isFinished: false,
  // },
  // {
  //   sousId: 2,
  //   stakingToken: serializedTokens.milk,
  //   earningToken: serializedTokens.kitten,
  //   contractAddress: {
  //     10001: '0xE570903E4c519Fb5AdF3c9917fef24DE5611aa0E',
  //     10000: '0xE570903E4c519Fb5AdF3c9917fef24DE5611aa0E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '1.0',
  //   sortOrder: 2,
  //   isFinished: false,
  // },
]

export default pools
