import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'MuesliSwap DEX on Milkomeda',
  description: 'MuesliSwap DEX on Milkomeda - trade wrapped tokens on Cardano C1 sidechain',
  image: 'https://ada.muesliswap.com/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  // let basePath
  // if (path.startsWith('/swap')) {
  //   basePath = '/swap'
  // } else if (path.startsWith('/add')) {
  //   basePath = '/add'
  // } else if (path.startsWith('/remove')) {
  //   basePath = '/remove'
  // } else if (path.startsWith('/teams')) {
  //   basePath = '/teams'
  // } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
  //   basePath = '/voting/proposal'
  // } else {
  //   basePath = path
  // }

  return {
    title: 'MuesliSwap DEX on Milkomeda',
  }

  // switch (basePath) {
  //   case '/':
  //     return {
  //       title: `${t('Home')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/swap':
  //     return {
  //       title: `${t('Exchange')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/add':
  //     return {
  //       title: `${t('Add Liquidity')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/remove':
  //     return {
  //       title: `${t('Remove Liquidity')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/liquidity':
  //     return {
  //       title: `${t('Liquidity')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/find':
  //     return {
  //       title: `${t('Import Pool')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/competition':
  //     return {
  //       title: `${t('Trading Battle')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/prediction':
  //     return {
  //       title: `${t('Prediction')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/prediction/leaderboard':
  //     return {
  //       title: `${t('Leaderboard')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/farms':
  //     return {
  //       title: `${t('Farms')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/farms/auction':
  //     return {
  //       title: `${t('Farm Auctions')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/pools':
  //     return {
  //       title: `${t('Pools')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/lottery':
  //     return {
  //       title: `${t('Lottery')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/collectibles':
  //     return {
  //       title: `${t('Collectibles')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/ifo':
  //     return {
  //       title: `${t('Initial Farm Offering')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/teams':
  //     return {
  //       title: `${t('Leaderboard')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/profile':
  //     return {
  //       title: `${t('Your Profile')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/profile/tasks':
  //     return {
  //       title: `${t('Task Center')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/voting':
  //     return {
  //       title: `${t('Voting')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/voting/proposal':
  //     return {
  //       title: `${t('Proposals')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/voting/proposal/create':
  //     return {
  //       title: `${t('Make a Proposal')} | ${t('MuesliSwap')}`,
  //     }
  //   case '/info':
  //     return {
  //       title: `${t('Overview')} | ${t('MuesliSwap Info & Analytics')}`,
  //       description: 'View statistics for MuesliSwap exchanges.',
  //     }
  //   case '/info/pools':
  //     return {
  //       title: `${t('Pools')} | ${t('MuesliSwap Info & Analytics')}`,
  //       description: 'View statistics for MuesliSwap exchanges.',
  //     }
  //   case '/info/tokens':
  //     return {
  //       title: `${t('Pools')} | ${t('MuesliSwap Info & Analytics')}`,
  //       description: 'View statistics for MuesliSwap exchanges.',
  //     }
  //   case '/launchpad/newtoken':
  //     return {
  //       title: `${t('Create a token')} | ${t('MuesliPad')}`,
  //     }
  //   case '/launchpad':
  //     return {
  //       title: `${t('Presales')} | ${t('MuesliPad')}`,
  //     }
  //   case '/presale':
  //     return {
  //       title: `${t('Presale')} | ${t('MuesliPad')}`,
  //     }
  //   default:
  //     return null
  // }
}
