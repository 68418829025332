import { MenuEntry } from '@muesliswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  // {
  //   label: t('Presale'),
  //   icon: 'InfoIcon',
  //   href: '/presale',
  // },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
      {
        label: "Farms",
        href: '/farms',
      }
    ],
  },
  // {
  //   label: t('Farms'),
  //   icon: 'FarmIcon',
  //   href: '/farms',
  // },
  {
    label: "Charts",
    icon: 'InfoIcon',
    href: '/info',
  },
  {
    label: 'Mainnet',
    href: 'https://muesliswap.com',
  },
  {
    label: "Bridge",
    items: [
      {
        label: "Celer",
        href: "https://cbridge.celer.network/",
      },
    ]
  },
  // {
  //   label: t('Pools'),
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: t('Launchpad'),
  //   icon: 'RocketIcon',
  //   items: [
  //     {
  //       label: t('Presales'),
  //       href: '/launchpad',
  //     },
  //     {
  //       label: t('Create token'),
  //       href: '/launchpad/newtoken',
  //     },
  //     {
  //       label: t('Token locker'),
  //       href: '/locker',
  //     },
  //   ],
  // },
  // {
  //   label: t('Games'),
  //   icon: 'TicketIcon',
  //   href: 'https://bch.games/play/3693c16d',
  //   affiliate: true,
  // },
  {
    label: "Social",
    // icon: "TelegramIcon",
    items: [
      {
        label: "Twitter",
        href: "https://twitter.com/MuesliSwapTeam"
      },
      {
        label: "Telegram",
        href: "https://t.me/muesliswapADA",
      },
      {
        label: "Discord",
        href: "https://discord.gg/vHzSXRWBJR",
      },
      {
        label: "Medium",
        href: "https://medium.com/@muesliswap"
      },
    ]
  },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: "Docs",
        href: "https://docs.muesliswap.com/milkomeda",
      },
      {
        label: t('Contact'),
        href: 'https://docs.muesliswap.com/contact-us',
      },
      {
        label: t('Github'),
        href: 'https://github.com/muesliswap',
      }
    ],
  },
]

export default config
