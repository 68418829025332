import { ChainId, Token } from '@muesliswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wada: new Token(
    MAINNET,
    '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
    18,
    'WADA',
    'Wrapped Ada',
    '',
  ),
  myield: new Token(
    MAINNET, 
    '0x2D7289Df2f41a25D3A628258081aD7B99eb4C83B', 
    18, 
    'MYIELD', 
    'MuesliSwap Yield Token', 
    ''
  ),
  usdc: new Token(
    MAINNET,
    '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
    6,
    'USDC',
    'USD Coin (Multichain)',
    '',
  ),
  usdc_ce: new Token(
    MAINNET,
    '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
    6,
    'USDC_ce',
    'USD Coin (Celer)',
    '',
  ),
  usdt: new Token(
    MAINNET,
    '0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
    6,
    'USDT',
    'Tether USD (Multichain)',
    '',
  ),
  usdt_ce: new Token(
    MAINNET,
    '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
    6,
    'USDT_ce',
    'Tether USD (Celer)',
    '',
  ),
  wbtc: new Token(
    MAINNET,
    '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
    8,
    'WBTC',
    'Wrapped Bitcoin (Multichain)'
  ),
  wbtc_ce: new Token(
    MAINNET,
    '0x8d50a024B2F5593605d3cE8183Ca8969226Fcbf8',
    8,
    'WBTC_ce',
    'Wrapped Bitcoin (Celer)'
  ),
  eth: new Token(
    MAINNET,
    '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    18,
    'ETH',
    'Ethereum (Multichain)'
  ),
  eth_ce: new Token(
    MAINNET,
    '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
    18,
    'ETH_ce',
    'Ethereum (Celer)'
  ),
  dai: new Token(
    MAINNET,
    '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    18,
    'DAI',
    'DAI Stablecoin (Multichain)'
  ),
  dai_ce: new Token(
    MAINNET,
    '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
    18,
    'DAI',
    'DAI Stablecoin (Celer)'
  ),
  busd: new Token(
    MAINNET,
    '0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0',
    18,
    'BUSD',
    'Binance USD (Multichain)'
  ),
  busd_ce: new Token(
    MAINNET,
    '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
    18,
    'BUSD_ce',
    'Binance USD (Celer)'
  ),
  bnb: new Token(
    MAINNET,
    '0xFbdd194376de19a88118e84E279b977f165d01b8',
    18,
    'BNB',
    'Binance Token (Multichain)'
  ),
  bnb_ce: new Token(
    MAINNET,
    '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52',
    18,
    'BNB_ce',
    'Binance Token (Celer)'
  ),
  avax: new Token(
    MAINNET,
    '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
    18,
    'AVAX',
    'Avalanche (Multichain)'
  ),
  ftm_ce: new Token(
    MAINNET,
    '0xc3FEc6F18dDb7583DA572374Ca8d11c6F0590dAe',
    18,
    'FTM',
    'Fantom (Celer)'
  ),
  ftm: new Token(
    MAINNET,
    '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
    18,
    'FTM',
    'Fantom (Multichain)'
  ),
  milk: new Token(
    MAINNET,
    '0x386e685B5cBAa7bC06e3Dd2eEcDC56105076e4Fa',
    18,
    'MILK',
    'MuesliSwap MILK Token (Cardano)'
  ),
  wmt: new Token(
    MAINNET,
    '0x4D5C63c07463EFa5bb26EE0A6D8C39dd0e3DaBD9',
    6,
    'WMT',
    'World Mobile Token (Cardano)'
  ),
  eth_nomad: new Token(
    MAINNET,
    '0x5950F9B6EF36f3127Ea66799e64D0ea1f5fdb9D1',
    18,
    'ETH',
    'Ethereum (Nomad)',
  ),
  wbtc_nomad: new Token(
    MAINNET,
    '0x48AEB7584BA26D3791f06fBA360dB435B3d7A174',
    8,
    'WBTC nomad',
    'Wrapped Bitcoin (Nomad)'
  ),
  mvoucher: new Token(
    MAINNET,
    '0xbef6624766cc23717ABa4A2f59D98Bb3B42774ff',
    18,
    'MVoucher',
    'MVoucher',
  ),

}

export const testnetTokens = {
  wada: new Token(
    TESTNET,
    '0x65a51E52eCD17B641f8F0D1d56a6c9738951FDC9',
    18,
    'WADA',
    'Wrapped ADA',
    'https://example.com/',
  ),
  myield: new Token(
    TESTNET, 
    '0x2D7289Df2f41a25D3A628258081aD7B99eb4C83B', 
    18, 
    'MYIELD', 
    'MuesliSwap Yield Token', 
    ''
  ),
  btc: new Token(TESTNET, '0xF98f6C2836deBab4D8c0E2e03FedA0C7132421E9', 18, 'BTC', 'Bitcoin (Test)', ''),
  eth: new Token(TESTNET, '0x4D395fa795Ee1459F89430887Df3d59Cb14E56cF', 18, 'ETH', 'Ethereum (Test)', ''),
  milk: new Token(TESTNET, '0xd7AF0D30aa0B0e2d48AFd332D5DDb8122B550d65', 18, 'MILK', 'MILK Token (Test)', ''),
  usdc: new Token(
    TESTNET,
    '0xC12F6Ee5c853393105f29EF0310e61e6B494a70F',
    18,
    'USDC',
    'USD Coin',
    'https://example.com/',
  ),
  // milk: new Token(
  //   TESTNET,
  //   '0xdAa1B301A0a4d797C1d805F0C4705C795D0D3270',
  //   18,
  //   'MILK',
  //   'MuesliSwap Token',
  //   'https://muesliswap.com',
  // ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
