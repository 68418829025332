import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@muesliswap/uikit'
import path from 'path'

const StyledNav = styled.nav<{ marginTop?: string, marginBottom?: string, marginLeft?: string, marginRight?: string }>`
  margin-top: ${({ marginTop }) => marginTop || "0"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "40px"};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
`

const getActiveIndex = (pathname: string): number => {
  // Wrapped MuesliSwap interface
  if (pathname.includes("/info")) {
    return 3
  }
  if (pathname.includes('/farms')) {
    return 2
  }
  if (
    pathname.includes('/pool') ||
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/remove') ||
    pathname.includes('/find') ||
    pathname.includes('/liquidity')
  ) {
    return 1
  }
  return 0
}

interface NavProps {
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
}

const Nav = (props: NavProps) => {
  const location = useLocation()
  const { marginTop, marginBottom, marginLeft, marginRight } = props

  return (
    <StyledNav 
      marginTop={marginTop} marginBottom={marginBottom} 
      marginLeft={marginLeft} marginRight={marginRight}
    >
      <ButtonMenu activeIndex={getActiveIndex(location.pathname)} scale="sm" variant="subtle">
        <ButtonMenuItem id="swap-nav-link" to="/swap" as={Link}>
          Trade
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" to="/pool" as={Link}>
          Add Liquidity
        </ButtonMenuItem>
        <ButtonMenuItem id="farms-nav-link" to="/farms" as={Link}>
          Farm
        </ButtonMenuItem>
        <ButtonMenuItem id="analytics-nav-link" to="/info" as={Link}>
          Analytics
        </ButtonMenuItem>
        {/* <ButtonMenuItem id="farms-nav-link" to="/pools" as={Link}>
          {t('Stake')}
        </ButtonMenuItem> */}
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
