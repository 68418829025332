import { serializeTokens } from './tokens'
import { BridgeType, SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'mue',
  //   lpAddresses: {
  //     10001: '',
  //     10000: '',
  //   },
  //   token: serializedTokens.milk,
  //   quoteToken: serializedTokens.wbch,
  // },
  {
    pid: 1,
    lpSymbol: 'MYIELD-ADA LP',
    lpAddresses: {
      // FIXME: use real addrs for both
      2001: '0xEFDdEAbcc78e5ff67971d2a74B358cB365B726Ef',
      200101: '0x3dE5A64e86334aAb280a0dc91B2912A2a20B1c05',
    },
    token: serializedTokens.myield,
    quoteToken: serializedTokens.wada,
  },
  {
    pid: 17,
    lpSymbol: 'MILK-ADA LP',
    lpAddresses: {
      2001: "0xE16Ed0e45e543D002d786bF418BcF86Cc78e716F",
      200101: "0xE16Ed0e45e543D002d786bF418BcF86Cc78e716F",
    },
    token: serializedTokens.milk,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.cardano,
  },
  {
    pid: 2,
    lpSymbol: 'BTC-ADA LP',
    lpAddresses: {
      2001: '0xaDbaBAf06c98785f09482F41Cf862edBE7889160',
      200101: '0xaDbaBAf06c98785f09482F41Cf862edBE7889160',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain
  },
  {
    pid: 3,
    lpSymbol: 'ETH-ADA LP',
    lpAddresses: {
      2001: '0xCb5d209493569545668aEfCd8020a22050800Ab7',
      200101: '0xCb5d209493569545668aEfCd8020a22050800Ab7',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain
  },
  {
    pid: 4,
    lpSymbol: 'USDC-ADA LP',
    lpAddresses: {
      2001: '0x55B5a4f122Ce1E77Dc1e2C015b389BF94F8Cfb91',
      200101: '0x55B5a4f122Ce1E77Dc1e2C015b389BF94F8Cfb91',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain
  },
  {
    pid: 18,
    lpSymbol: 'WMT-ADA LP',
    lpAddresses: {
      2001: "0x3E87195AB69Aa1Ee8c54121c9f0a41bC9A59A0C4",
      200101: "0x3E87195AB69Aa1Ee8c54121c9f0a41bC9A59A0C4",
    },
    token: serializedTokens.wmt,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.cardano,
  },
  {
    pid: 19,
    lpSymbol: 'WMT-multiUSDC LP',
    lpAddresses: {
      2001: "0x8eEB37D204109a08484AEe3edeD8d7856f861d99",
      200101: "0x8eEB37D204109a08484AEe3edeD8d7856f861d99",
    },
    token: serializedTokens.wmt,
    quoteToken: serializedTokens.usdc,
    bridge: BridgeType.cardano,
  },
  {
    pid: 5,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      2001: '0x44634f8E1e1588d5ae1F387269d0b260d5AA7ff1',
      200101: '0x44634f8E1e1588d5ae1F387269d0b260d5AA7ff1',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
    bridge: BridgeType.multichain
  },
  {
    pid: 6,
    lpSymbol: 'BTC-ETH LP',
    lpAddresses: {
      2001: '0xEB3fb88f76C5E9Eb1a70A7cB1E156A1d7449e494',
      200101: '0xEB3fb88f76C5E9Eb1a70A7cB1E156A1d7449e494',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.eth,
    bridge: BridgeType.multichain
  },
  {
    pid: 7,
    lpSymbol: 'ceUSDC-ADA LP',
    lpAddresses: {
      2001: '0x0724626A353302e74b88E52871D0e99A7bd2Ed79',
      200101: '0x0724626A353302e74b88E52871D0e99A7bd2Ed79',
    },
    token: serializedTokens.usdc_ce,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.celer
  },
  {
    pid: 8,
    lpSymbol: 'ceBTC-ceETH LP',
    lpAddresses: {
      2001: '0xF9fe2b442905510Cb86dacC11C898D6c73aC9C93',
      200101: '0xF9fe2b442905510Cb86dacC11C898D6c73aC9C93',
    },
    token: serializedTokens.wbtc_ce,
    quoteToken: serializedTokens.eth_ce,
    bridge: BridgeType.celer
  },
  {
    pid: 9,
    lpSymbol: 'ceETH-ADA LP',
    lpAddresses: {
      2001: '0x97275A18c227aAA38a62405985Cd7Bd67955B270',
      200101: '0x97275A18c227aAA38a62405985Cd7Bd67955B270',
    },
    token: serializedTokens.eth_ce,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.celer
  },
  {
    pid: 10,
    lpSymbol: 'ceUSDC-ceUSDT LP',
    lpAddresses: {
      2001: '0x3609CaFA1B1b10F262F95F45BAaF36378BC4dcd2',
      200101: '0x3609CaFA1B1b10F262F95F45BAaF36378BC4dcd2',
    },
    token: serializedTokens.usdt_ce,
    quoteToken: serializedTokens.usdc_ce,
    bridge: BridgeType.celer,
  },
  {
    pid: 11,
    lpSymbol: 'DAI-USDC LP',
    lpAddresses: {
      2001: '0x22AbEF76439B69a4B1dED532Ba33Be056C106364',
      200101: '0x22AbEF76439B69a4B1dED532Ba33Be056C106364',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.usdc,
    bridge: BridgeType.multichain,
  },
  {
    pid: 12,
    lpSymbol: 'ceBNB-ADA LP',
    lpAddresses: {
      2001: '0x3039e0f8BEc02Afa4428009199169b3b2d5B0561',
      200101: '0x3039e0f8BEc02Afa4428009199169b3b2d5B0561',
    },
    token: serializedTokens.bnb_ce,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.celer,
  },
  {
    pid: 13,
    lpSymbol: 'AVAX-ADA LP',
    lpAddresses: {
      2001: '0x172fe7FC5263f6ebAda9A58FFabEec68397995BD',
      200101: '0x172fe7FC5263f6ebAda9A58FFabEec68397995BD',
    },
    token: serializedTokens.avax,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain,
  },
  {
    pid: 14,
    lpSymbol: 'BNB-ADA LP',
    lpAddresses: {
      2001: "0xbEDf71c174cdF142677A841bDD78f8695b29B85f",
      200101: "0xbEDf71c174cdF142677A841bDD78f8695b29B85f",
    },
    token: serializedTokens.bnb,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain,
  },
  {
    pid: 15,
    lpSymbol: 'ceFTM-ADA LP',
    lpAddresses: {
      2001: "0x0CBF6A14edA9bf6b5029d0358e82143Bc84B1344",
      200101: "0x0CBF6A14edA9bf6b5029d0358e82143Bc84B1344",
    },
    token: serializedTokens.ftm_ce,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.celer,
  },
  {
    pid: 16,
    lpSymbol: 'FTM-ADA LP',
    lpAddresses: {
      2001: "0xa138a11d45f1f00b16cfd07fc4777a03379b8f06",
      200101: "0xa138a11d45f1f00b16cfd07fc4777a03379b8f06",
    },
    token: serializedTokens.ftm,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.multichain,
  },
  {
    pid: 20,
    lpSymbol: 'nomadBTC-ADA LP',
    lpAddresses: {
      2001: '0x66b503C01DdBb6D0f592b9B4023928701C0A45e1',
      200101: '0x66b503C01DdBb6D0f592b9B4023928701C0A45e1',
    },
    token: serializedTokens.wbtc_nomad,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.nomad,
  },
  {
    pid: 21,
    lpSymbol: 'nomadETH-ADA LP',
    lpAddresses: {
      2001: '0x0201E7088708a032dAdE785670255847c9B51321',
      200101: '0x0201E7088708a032dAdE785670255847c9B51321',
    },
    token: serializedTokens.eth_nomad,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.nomad,
  },
  {
    pid: 22,
    lpSymbol: 'ceBUSD-ADA LP',
    lpAddresses: {
      2001: '0xE708fd444CAEf29E2004923cc29Ac4Aef006c9E9',
      200101: '0xE708fd444CAEf29E2004923cc29Ac4Aef006c9E9',
    },
    token: serializedTokens.busd_ce,
    quoteToken: serializedTokens.wada,
    bridge: BridgeType.celer,
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'MILK-ADA LP',
  //   lpAddresses: {
  //     2001: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
  //     200101: '0x61Ef5586f18869fd626302889251b6b4bB9855Ac',
  //   },
  //   token: serializedTokens.milk,
  //   quoteToken: serializedTokens.wada,
  // },
]

export default farms
