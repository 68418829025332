import { Collections, CollectionKey } from './types'

const collections: Collections = {
  [CollectionKey.PANCAKE]: {
    name: 'Pancake Bunnies',
    address: {
      2001: '',
      200101: '',
    },
  },
}

export default collections
