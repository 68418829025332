export default {
  masterChef: {
    200101: '0x39B8571f510C8afbb1595FF7CB2d048df699aA71',
    2001: '0x39B8571f510C8afbb1595FF7CB2d048df699aA71',
  },
  sousChef: {
    10001: '0x0427692B550b93f16d8BcA68D053C0e4847f52CD',
    10000: '0x7c84477aF60C948D12db8B4e9c49FFBE6378cf76', // deprecated: only for Spoon
    200101: '0x39B8571f510C8afbb1595FF7CB2d048df699aA71',
    2001: '0x39B8571f510C8afbb1595FF7CB2d048df699aA71'
  },
  lotteryV2: {
    10001: '0x5790c3534F30437641541a0FA04C992799602998',
    10000: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    10000: '0x872a732C6168c9cB37b7822FF927cd4345d7149C',
    10001: '0xAbC9A63De2fCf381A61bC2376C5587352765245d',
    200101: '0x3c0950da6e367c05c7e47d2d90ef52a4d10f01d1',
    2001: '0xe520aF265c5488104D9f63091b80F0a9feBF5070'
  },
  pancakeProfile: {
    10000: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    10001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    10000: '0xDf7952B35f24aCF7fC0487D01c8d1000090a60DBa07',
    10001: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    10000: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    10001: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    10000: '0xE7e53A7e9E3Cf6b840f167eF69519175c410001e149',
    10001: '',
  },
  pointCenterIfo: {
    10000: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    10001: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2910001e3',
  },
  bunnySpecial: {
    10000: '0xFee8A195570a18461146F401d6033f5ab3380849',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    10000: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    10001: '0xC787F45B833721ED3aC46E99b703B3E1E01abb10001',
  },
  easterNft: {
    10000: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    10001: '0x24ec6962dbe874F6B67B5C50857100001000067fA0854F',
  },
  cakeVault: {
    10000: '0x624d71509e57D4E859fd7Caa6c211D81c691FDCc', // should be the final one
    10001: '0xc74Ccc3eBfdcA788c79371307aB08Af80B82Cd29', // that's the new one,
    200101: '0x0000000000000000000000000000000000000000',
    2001: '0x0000000000000000000000000000000000000000',
  },
  predictions: {
    10000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    10001: '',
  },
  chainlinkOracle: {
    10000: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    10001: '',
  },
  bunnySpecialCakeVault: {
    10000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    10001: '',
  },
  bunnySpecialPrediction: {
    10000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    10001: '',
  },
  bunnySpecialLottery: {
    10000: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    10001: '0x382cB410001110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    10000: '0xb92Ab7c1edcb273AbA24b0610000cEb3681654805D2',
    10001: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}
