import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('presale/selectCurrency')
export const typeInput = createAction<{ field: Field; typedValue: string }>('presale/typeInput')
export const replacePresaleState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
}>('presale/replacePresaleState')
export const setRecipient = createAction<{ recipient: string | null }>('presale/setRecipient')
